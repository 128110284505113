<template>
    <img
        class="w-auto"
        :src="isDark ? '/farbcode-logo.svg' : '/farbcode-logo-dark.svg'"
        alt="Logo">
</template>

<script setup>
const isDark = useDark({
    disableTransition: false
})
</script>